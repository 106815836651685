import { Suspense, useMemo, lazy } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { CssBaseline } from "@material-ui/core";
import GlobalLoading from "components/main/GlobalLoading";
import FullHeight from "components/layout/FullHeight";
import "typeface-roboto";

const App = lazy(() =>
  import(/* webpackChunkName: 'App'*/ "components/main/App")
);

function Root() {
  const loadedAt = useMemo(() => moment(), []);

  return (
    <>
      <CssBaseline />
      <FullHeight>
        <Suspense fallback={<GlobalLoading loadedAt={loadedAt} />}>
          <App loadedAt={loadedAt} />
        </Suspense>
      </FullHeight>
    </>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
