import { useState, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import moment from "moment";
import appConfig from "appConfig";
import loading from "./loading-transparent.gif";

const POLL_MS = 100;

function shouldShow(loadedAt, delay) {
  return moment().diff(loadedAt) > delay;
}

const useStyles = makeStyles({
  imgSmall: {
    height: 100
  },
  img: {
    height: 150
  }
});

export function LoadingIndicator({ loadedAt, delay, size }) {
  const classes = useStyles();

  const finalDelay = delay === false ? 0 : delay || appConfig.loadingDelay;
  const [showing, setShowing] = useState(
    loadedAt ? shouldShow(loadedAt, finalDelay) : false
  );

  useEffect(() => {
    if (loadedAt) return () => {};
    const handle = setTimeout(() => setShowing(true), finalDelay);
    return () => clearTimeout(handle);
  }, [delay, finalDelay, loadedAt]);

  useEffect(() => {
    if (!loadedAt) return () => {};
    const handle = setInterval(() => {
      setShowing(shouldShow(loadedAt, finalDelay));
    }, POLL_MS);
    return () => clearInterval(handle);
  }, [delay, finalDelay, loadedAt]);

  if (!showing) return null;

  return (
    <Box
      height="100%"
      flexGrow={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box flexGrow={1} />
      <img
        className={size === "small" ? classes.imgSmall : classes.img}
        alt=""
        src={loading}
      />
      <Box flexGrow={1.5} />
    </Box>
  );
}

export default LoadingIndicator;
