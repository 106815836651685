import LoadingIndicator from "components/organisms/LoadingIndicator";
import { Box } from "@material-ui/core";

export function GlobalLoading(props) {
  return (
    <Box height="100%" flexGrow={1} display="flex" flexDirection="column">
      <LoadingIndicator {...props} />
    </Box>
  );
}

export default GlobalLoading;
