import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    display: "flex"
  }
});

export function FullHeight({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

export default FullHeight;
